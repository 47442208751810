
<template>
  <div class="match_gametype">
    <div class="gametype" @click="changeGameType(gameType)">
      <span class="pic">
        <img src="../../../../assets/images/icon/type/1/all.svg" v-if="gameType == sportsConst.GAME_TYPE_ALL">
        <img src="../../../../assets/images/icon/type/1/football-ico.png" v-if="gameType == sportsConst.GAME_TYPE_SOCCER">
        <img src="../../../../assets/images/icon/type/1/basketball-ico.png" v-if="gameType == sportsConst.GAME_TYPE_BASKETBALL">
        <img src="../../../../assets/images/icon/type/1/baseball-ico.png" v-if="gameType == sportsConst.GAME_TYPE_BASEBALL">
        <img src="../../../../assets/images/icon/type/1/hockey-ico.png" v-if="gameType == sportsConst.GAME_TYPE_HOCKEY">
        <img src="../../../../assets/images/icon/type/1/volleyball-ico.png" v-if="gameType == sportsConst.GAME_TYPE_VOLLEYBALL">
        <img src="../../../../assets/images/icon/type/1/esport-ico.png" v-if="gameType == sportsConst.GAME_TYPE_ESPORTS">
        <img src="../../../../assets/images/icon/type/1/usa-football-ico.png" v-if="gameType == sportsConst.GAME_TYPE_USA_FOOTBALL">
      </span>
      <span class="txt">
        <font v-if="gameType == sportsConst.GAME_TYPE_ALL">전체</font>
        <font v-if="gameType == sportsConst.GAME_TYPE_SOCCER">축구</font>
        <font v-if="gameType == sportsConst.GAME_TYPE_BASKETBALL">농구</font>
        <font v-if="gameType == sportsConst.GAME_TYPE_BASEBALL">야구</font>
        <font v-if="gameType == sportsConst.GAME_TYPE_HOCKEY">아이스하키</font>
        <font v-if="gameType == sportsConst.GAME_TYPE_VOLLEYBALL">배구</font>
        <font v-if="gameType == sportsConst.GAME_TYPE_ESPORTS">ESPORTS</font>
        <font v-if="gameType == sportsConst.GAME_TYPE_USA_FOOTBALL">미식축구</font>
      </span>
      <span class="count">
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_ALL">{{ $store.state.gameCount.alltypecount }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_SOCCER">{{ $store.state.gameCount.soccer }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_BASKETBALL">{{ $store.state.gameCount.basketball }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_BASEBALL">{{ $store.state.gameCount.baseball }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_HOCKEY">{{ $store.state.gameCount.hockey }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_VOLLEYBALL">{{ $store.state.gameCount.volleyball }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_ESPORTS">{{ $store.state.gameCount.esports }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_USA_FOOTBALL">{{ $store.state.gameCount.football }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import sportsConst from "@/common/sportsConst";
import {getLeagues, getSportsGameCountGroupByType} from "@/network/sportsRequest";
import {mapGetters} from "vuex";
import LeftLeagues from "@/views/afront/sports/league/LeftLeagues.vue";

export default {
  name: "LeftSportsGameType",
  components: {LeftLeagues},
  props: {
    gameType: {
      type: Number,
      default() {
        return sportsConst.GAME_DISPLAYPOSITION_1x2;
      }
    },
  },
  data() {
    return {
      sportsConst,
      alltypecount: 0,
    }
  },

  methods:{
    changeGameType(gameType){
      if(gameType == this.$store.state.selectedGameType){
        this.$store.state.selectedGameType = sportsConst.GAME_TYPE_NOT_SELECTED
      } else {
        this.$store.state.selectedGameType = gameType
      }
      if(gameType == sportsConst.GAME_TYPE_NOT_SELECTED ) {
        // this.$store.state.leagueList.forEach(league => {
        //   this.$set(league, 'isShow', false);
        // })
      } else if(gameType == sportsConst.GAME_TYPE_ALL){
        this.$store.state.leagueList.forEach(league => {
          this.$set(league, 'isShow', true);
        })
      }else {
        this.$store.state.leagueList.forEach(league => {
          this.$set(league, 'isShow', false);
          if (gameType == league.gameTypeId) {
            this.$set(league, 'isShow', true);
          }
        })
      }
    }
  },
  created() {

  }
}
</script>


<style scoped>

.match_gametype{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.match_gametype .gametype{
  width: 100%;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(180deg, #ffffff 0, #ffffff) !important;
  color: #353535;
  margin: .2rem;
  padding: 0 .5rem;
  border-radius: .5rem;

}
.match_gametype .gametype .pic{
  width: 10%;
}
.match_gametype .gametype .pic img{
  width: 2.3rem;
}

.match_gametype .gametype .txt{
  width: 60%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: .5rem;
}
.match_gametype .gametype .count{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.match_gametype .gametype .count .number{
  height: 2.2rem;
  border-radius: .5rem;
  background: #d3051c;
  color: #fff9ff;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>