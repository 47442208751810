<template>
  <div class="footer">
    <div class="pcf">
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdpartylogo/macaw.png" alt="">
      </div>
      <div class="item">
        <img src="../../assets/images/logo/thirdpartylogo/1x2game.png" alt="">
      </div>
      <div class="item">
        <img src="../../assets/images/logo/thirdpartylogo/7mojos.png" alt="">
      </div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/abslute.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/amigo.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/amatic.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/asiagame.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/astar.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/hacksaw.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/betgamestv.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/bgaming.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/bigtimegame.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/blueprint.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/boongo.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/caleta.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/dowin.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/evoplay.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/ezugi.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/fcfachai.png" alt=""></div>


    </div>
    <div class="mobilef">
      <router-link tag="div" to="/front/recharge" class="item">
        <i class="iconfont icon-chongzhi"></i>
        <span>충전</span>
      </router-link>
      <router-link tag="div" to="/front/exchange" class="item">
        <i class="iconfont icon-tikuan"></i>
        <span>환전</span>
      </router-link>
      <router-link tag="div" to="/front/coupon" class="item">
        <i class="iconfont icon-coupon btn-icon-small"></i>
        <span>쿠폰 <font v-if="$store.state.isLogin"> ({{ $store.state.userInfo.scoupon + $store.state.userInfo.gcoupon + $store.state.userInfo.dcoupon }})</font></span>

      </router-link>
      <router-link tag="div" to="/front/message" class="item">
        <i class="iconfont icon-message"></i>
        <span>쪽지</span>
      </router-link>
      <router-link tag="div" to="/front/customercenter" class="item">
        <i class="iconfont icon-kefu"></i>
        <span>문의</span>
      </router-link>

    </div>
  </div>
</template>

<script>

import PopupComp from "@/views/afront/notice/PopupComp.vue";

export default {
  name: "Footer",
  components: {PopupComp},
  data() {
    return {}
  },

  methods: {},
  created() {

  },
  watch: {}
}
</script>

<style scoped>
@import url("../../assets/css/front/afrontfoot.css");


</style>