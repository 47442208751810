

<template>
  <div>
    <div class="left_menu_casino_slot_img">
      <router-link tag="div" :to="{path:'/front/casino'}" @click.native="mobileMenuAction"><img src="../../../assets/images/left/casinoslot/casino.gif"></router-link>
      <router-link tag="div" :to="{path:'/front/slot'}" @click.native="mobileMenuAction"><img src="../../../assets/images/left/casinoslot/slot.gif"></router-link>

    </div>
<!--    <div class="left_menu_casino_slot_img" style="margin: 0">-->

<!--      <div style="width: 100%" @click="alertholdem"><img src="../../../assets/images/left/casinoslot/holdem.jpg"></div>-->
<!--    </div>-->
  </div>

</template>
<script>
export default {
  name: "LeftMenuLinkCasinoSlotComp",
  methods:{
    mobileMenuAction(){
      this.$bus.$emit('activeLeftMenu', false);
    },
    alertholdem(){
      this.$swal({
        title: '홀덤은 준비중에 있습니다',
        type: 'error',
        showCancelButton: false,
        showConfirmButton: true
      })
      return false;
    }
  }
}
</script>
<style scoped>
.left_menu_casino_slot_img{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left_menu_casino_slot_img div{
  width: 49.5%;
  cursor: pointer;
}
.left_menu_casino_slot_img img{
  width: 100%;
}

</style>