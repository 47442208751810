

<template>
  <div class="banner">
<!--    <div>-->
<!--      <router-link tag="a" to="/livetv" style="cursor: pointer">-->
<!--        <img alt="" src="../../../assets/images/banner/live.gif" style="width: 100%">-->
<!--      </router-link>-->
<!--    </div>-->
    <div>
      <a href="https://t.me/redapple7979" target="_blank">
        <img alt="" src="../../../assets/images/banner/telnotice.gif" style="width: 100%">
      </a>
    </div>
    <div>
      <a href="https://t.me/redap1004" target="_blank">
        <img alt="" src="../../../assets/images/banner/tel.gif" style="width: 100%">
      </a>
    </div>
    <div>
      <a href="https://레드애플.com" target="_blank">
        <img alt="" src="../../../assets/images/banner/www.gif" style="width: 100%">
      </a>
    </div>
    <div>
      <a href="https://www.rotowire.com/" target="_blank">
        <img alt="" src="../../../assets/images/banner/lineup.gif" style="width: 100%">
      </a>
    </div>
    <div>
      <a href="https://www.pragmaticplay.com/ko/%EA%B2%8C%EC%9E%84-%EC%A0%95%ED%92%88-%EC%9D%B8%EC%A6%9D/" target="_blank">
        <img alt="" src="../../../assets/images/banner/realslot.gif" style="width: 100%">
      </a>
    </div>
    <div>
      <router-link tag="a" to="/front/sportstv">
        <img alt="" src="../../../assets/images/banner/sportstv.gif" style="width: 100%">
      </router-link>
    </div>
  </div>

</template>
<script>
export default {
  name: "RightBannerComp"
}
</script>
<style scoped>
  .banner{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

  }
  .banner a{}
  .banner img{
    width: 100%;

  }
</style>